import logoMcD from '~/assets/img/brands/mc-donalds-logo.png';
import logoKfc from '~/assets/img/brands/kfc-logo.png';
import coupongLogo from '~/assets/img/coupon.png';
import logoStarbucks from '~/assets/img/brands/starbucks-logo.png';
import { Coupon } from '~/shared/types/Coupon';

export const getCouponLogo = (coupon: Coupon) => {
    const logoMap = {
        'McDonalds': logoMcD,
        'Starbucks': logoStarbucks,
        'Rostics': logoKfc
    };
    return logoMap[coupon.brand] ?? coupongLogo;
};
