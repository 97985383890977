import { Box, Button, Flex, Text } from '@mantine/core';
import { couponStyle, logoContainerStyle, cutoffContainerStyle, couponBodyStyle, logoStyle, brandStyle, priceStyle, validUntilStyle, valueStyle } from './styles.css';
import { theme } from '~/shared/theme';
import { getCouponLogo } from '../../utils/getCouponLogo';
import { Coupon } from '~/shared/types/Coupon';
import CheckIcon from '~/assets/icons/Check.svg';
import WebApp from '@twa-dev/sdk';


type Props = {
    coupon: Coupon;
    handleClick: (coupon: Coupon) => void;
    isDisabled?: boolean;
};

const CouponCard = ({ coupon, handleClick, isDisabled = false }: Props) => {
    const formattedDate = new Date(coupon.validity).toLocaleDateString('en', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
    const logo = getCouponLogo(coupon);

    const onClick = () => {
        if (coupon.is_used) {
            WebApp.showPopup({
                title: 'Code',
                message: coupon.code ?? ''
            });
        }
    };

    return (
        <div className={couponStyle} data-disabled={!!coupon.is_used} onClick={onClick}>
            <div className={logoContainerStyle}>
                <img src={logo} alt="Logo" className={logoStyle} />
                <span className={brandStyle}>{coupon.brand}</span>
            </div>
            <div className={cutoffContainerStyle} data-disabled={!!coupon.is_used}>
                <Flex className={couponBodyStyle} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Box fw='bold' className={valueStyle}>{coupon.value}</Box>
                    <Box fw='normal'>{coupon.title}</Box>
                    {!coupon.is_used && (
                        <Button
                            fw='normal'
                            m='1em 0 0.2em 0'
                            color={theme.colors.orange[5]}
                            variant='filled'
                            radius="xl"
                            className={priceStyle}
                            onClick={() => handleClick(coupon)}
                            disabled={isDisabled}
                        >
                            Buy for {coupon.price} PONs
                        </Button>
                    )}
                    <Box className={validUntilStyle}>Valid until {formattedDate ?? coupon.validity}</Box>
                </Flex>

                <Box style={{ marginLeft: 'auto', marginRight: '1rem', color: '#FF8A00' }}>
                    {coupon.is_used ? <CheckIcon /> : null}
                </Box>
            </div>
        </div>
    );
}

export default CouponCard;
