import { headers } from "./headers";
import { Coupon } from "../types/Coupon";

type Data = {
    user_id: number;
    category?: string;
    orderBy?: string;
    orderDir?: string;
}
const getCoupouns = async (
    data: Data,
    onSuccess?: (coupons: Coupon[]) => void,
    onError?: (e: any) => void
) => {
    const { user_id, category, orderBy, orderDir } = data;
    let args: string[] = [];
    if (category) {
        args.push(`category=${category.toLowerCase()}`);
    }
    if (orderBy) {
        args.push(`orderBy=${orderBy.toLowerCase()}`);
    }
    if (orderDir) {
        args.push(`orderDir=${orderDir.toLowerCase()}`);
    }
    const collectedArgs = args.length ? `?${args.join('&')}` : '';
    try {
        const response = await fetch(`${import.meta.env.VITE_API_URL}/coupons/${user_id}${collectedArgs}`, { headers });
        const res = await response.json();
        if (response.ok) {
            onSuccess?.(res);
        } else {
            onError?.(res);
        }
    } catch (e) {
        console.error(e);
        onError?.(e);
    }
};

export default getCoupouns;
