import { Input, Flex, Select } from '@mantine/core';
import { Page } from '~/shared/components/Page';
import SearchIcon from '~/assets/icons/Search.svg';
import FilterIcon from '~/assets/icons/Filter.svg';
import SortIcon from '~/assets/icons/Sort.svg';
import CouponCard from './components/CouponCard'; // Adjust the import if necessary


import { useEffect, useState } from 'react';
import { Coupon } from '~/shared/types/Coupon';
import { useUserContext } from '~/shared/UserContext';
import getCoupouns from '~/shared/api/getCoupouns';
import buyCoupon from '~/shared/api/buyCoupon';
import { sortIconStyle } from './styles.css';
import { Spinner } from '~/shared/components/Spinner';
import { ErrorNotice } from '~/shared/components/ErrorNotice';
import WebApp from '@twa-dev/sdk';
import getBalance from '~/shared/api/getBalance';

export const CouponPage = () => {
    const { user, setUser } = useUserContext();
    const [coupons, setCoupons] = useState<Coupon[]>([]);
    const [search, setSearch] = useState('');

    const [status, setStatus] = useState('loading');
    const [error, setError] = useState('');
    const categories = ['Food', 'Tech'];
    const [category, setCategory] = useState('');
    const orderOptions = ['Price', 'Validity'];
    const [orderBy, setOrderBy] = useState('Price');
    const [orderDir, setOrderDir] = useState('asc');
    const [disabled, setDisabled] = useState<number | null>(null);

    useEffect(() => {
        if (user?.id) {
            setStatus('loading');
            getCoupouns(
                { user_id: user.id, category, orderBy, orderDir },
                c => { setCoupons(c); setStatus('success'); },
                e => { setStatus('error'); setError(e.message); }
            );
        }
    }, [user?.id, category, orderBy, orderDir]);

    const handleClick = (coupon: Coupon) => {
        if (user?.id && !disabled) {
            setDisabled(coupon.id);
            buyCoupon(
                { user_id: user.id, coupon_id: coupon.id },
                () => {
                    WebApp.showPopup({
                        title: 'Success',
                        message: coupon.code ? `Code: ${coupon.code}` : ''
                    });
                    setDisabled(null);
                    setStatus('loading');
                    getCoupouns(
                        { user_id: user.id, category, orderBy, orderDir },
                        c => { setCoupons(c); setStatus('success'); },
                        () => { setStatus('error'); }
                    );
                    getBalance(user.id, setUser);
                },
                e => { WebApp.showAlert(e.message ?? e.error); setDisabled(null); }
            );
        }
    };

    return (
        <>
            <Flex
                direction='column'
                align='center'
                w='100dvw'
                h='calc(100dvh - 65px)'
                justify='flex-start'
                style={{ overflowY: 'scroll', overflowX: 'hidden' }}
            >
                <Page
                    headline={(user?.balance ?? 0).toString()}
                    smallerHeadline="PONs"
                    subheadline="Gold"
                    childBoxStyle={{ width: 'calc(100% - 1.5em)', margin: '.5em .75em' }}
                >
                    <Input
                        placeholder="Search..."
                        value={search}
                        onChange={e => setSearch(e.target.value.toLowerCase())}
                        leftSection={<SearchIcon />}
                        style={{ borderRadius: 'xl' }}
                    />
                    <Flex
                        justify="space-between"
                        gap="1ch"
                        style={{ marginTop: '1rem' }}
                    >
                        <Select
                            placeholder='Category'
                            variant='filled'
                            color='grey.1'
                            style={{ flex: 1 }}
                            leftSection={<FilterIcon />}
                            clearable
                            data={categories}
                            value={category}
                            onChange={v => setCategory(v ?? '')}
                        />
                        <Select
                            placeholder='Sort'
                            variant='filled'
                            color='grey.1'
                            style={{ flex: 1 }}
                            leftSection={
                                <SortIcon
                                    onClick={() => setOrderDir(orderDir === 'asc' ? 'desc' : 'asc')}
                                    data-value={orderDir}
                                    className={sortIconStyle}
                                />}
                            data={orderOptions}
                            value={orderBy}
                            onChange={v => setOrderBy(v ?? '')}
                        />
                    </Flex>
                    {status === 'loading' ? <Spinner /> : (
                        status === 'error' ? <ErrorNotice message={error} /> : (
                            coupons.filter(c => !search ||
                                c.title?.toLowerCase().includes(search) ||
                                c.value?.toLowerCase().includes(search) ||
                                c.brand?.toLowerCase().includes(search) ||
                                c.category?.toLowerCase().includes(search)
                            ).map(coupon => (
                                <CouponCard
                                    key={`coupon-${coupon.id}`}
                                    coupon={coupon}
                                    handleClick={handleClick}
                                    isDisabled={disabled === coupon.id}
                                />
                            ))
                        )
                    )}
                </Page>
            </Flex>
        </>
    );
}
