import React, { useContext, createContext, useState, useEffect, } from 'react';
import WebApp from '@twa-dev/sdk'
import getBalance from './api/getBalance';
import { User } from './types/User';


interface IUserContext {
    user: User | null;
    setUser: (user: User) => void;
}

export const UserContext = createContext({} as IUserContext);

export function ProvideGameContext({ children }: { children: React.ReactNode }) {
    const context = useProvidedUserContext();

    return (
        <UserContext.Provider value={context}>
            {children}
        </UserContext.Provider>
    );
}

export function useUserContext() {
    const context = useContext(UserContext);

    return context;
}

function useProvidedUserContext() {
    const [user, setUser] = useState<User | null>(null);

    const userId = WebApp.initDataUnsafe.user?.id;
    useEffect(() => {
        if (userId) {
            getBalance(userId, setUser);
        }
    }, [userId]);

    const context: IUserContext = {
        user,
        setUser
    };

    return context;
}
